const appEnv = {
  ENV: 'test',
  NAMESPACE: '',
  LOGO_IMAGE: '/assets/images/tl_logo.svg',
  WEB_ENDPOINT: 'https://test.tabi-labo.com',
  CMS_ENDPOINT: 'https://cms-test.tabi-labo.com',
  API_ENDPOINT: 'https://cms-api-test.tabi-labo.com/api/v1',
  AUTH_ENDPOINT: 'https://cms-api-test.tabi-labo.com/auth',
  MANAGE_ENDPOINT: 'https://manage-test.tabi-labo.com',
  REPORT_ENDPOINT: 'https://analytics.tabi-labo.com',
};

export default appEnv;
